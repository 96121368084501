import React from "react";
import PropTypes from "prop-types";
import {kebabCase} from "lodash";
import {Helmet} from "react-helmet";
import {graphql, Link} from "gatsby";
import Layout from "../components/Layout";
import Content, {HTMLContent} from "../components/Content";
import {GatsbyImage} from "gatsby-plugin-image";

// eslint-disable-next-line
export const BlogPostTemplate = ({
                                   content,
                                   contentComponent,
                                   frontmatter,
                                   productimage,
                                   helmet,
                                 }) => {
  const PostContent = contentComponent || Content
  const imageStyle = {width: '120px', margin: "auto"}

  return (
    <section className="section">
      {helmet || ""}
      <div className="container content" style={{maxWidth: "1024px"}}>
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {frontmatter.title}
            </h1>
            <p>{frontmatter.description}</p>
            <PostContent content={content}/>
            {frontmatter.amazonlink === null ?
              null : (
                <div>
                  <div className="columns is-centered ">
                    <div className="column is-4" style={{marginTop: `4rem`}}>
                      <a href={frontmatter.amazonlink} rel="nofollow">
                        <GatsbyImage style={imageStyle} image={frontmatter.productimage.childImageSharp.gatsbyImageData}/>
                      </a>
                    </div>
                    {frontmatter.iframeLink ? (
                      <div className="column is-4">
                        <iframe style={{"width": 120, "height": 240}} marginWidth="0" marginHeight="0"
                                scrolling="no" frameBorder="0" src={frontmatter.iframeLink}/>
                      </div>
                    ) : null}
                  </div>
                  <div style={{marginTop: `1rem`, textAlign: 'center'}}>
                    <a className="btn" href={frontmatter.amazonlink} rel="nofollow">Auf Amazon ansehen*</a>
                  </div>
                </div>
              )}
            {frontmatter.tags && frontmatter.tags.length ? (
              <div style={{marginTop: `4rem`}}>
                <h4>Tags</h4>
                <ul className="taglist">
                  {frontmatter.tags.map((tag) => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  frontmatter: PropTypes.object,
  productimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  helmet: PropTypes.object,
};

const BlogPost = ({data}) => {
  const {markdownRemark: post} = data;

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        frontmatter={post.frontmatter}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        amazonlink
        iframeLink
        productimage {
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 120, quality: 70)
          }
        }
        tags
      }
    }
  }
`;
